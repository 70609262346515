import { EnrollMemberStructure } from '@/hooks/useSaveWebStart';
import { RentersImplementation } from '@/pages/renters/hooks/useRentersImplementationBySchool';
import { TemplateSetEnum, TemplateSetProps } from '@/util/templateSet/templateSetTypes';

interface SessionDataBackend {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone?: number;
  agent?: number;
}

export interface Country {
  id: number;
  alpha2: string;
  name: string;
  calling_code: string;
}

export interface RentersPortalData {
  templateSet: {
    tokens: Record<TemplateSetEnum, TemplateSetProps>,
    tracking_code?: string,
  };
  refCode: string;
  renters: RentersImplementation;
}

export interface ServerDataBase {
  session?: SessionDataBackend;
  countries: Country[];
  gdpr: boolean;
  ppAck: boolean;
  ppUpdated: boolean;
  USA: Country;
  uuid?: string;
}

export interface ServerDataForm extends ServerDataBase {
  form: EnrollMemberStructure;
}

export interface RentersServerData extends ServerDataForm {
  rentersPortal: RentersPortalData;
  offer_available?: boolean;
  housing_policy_available?: boolean;
  trackingCodes?: string[];
}

interface WindowLocal<T extends ServerDataBase> extends Window {
  serverData?: T
  nonce: string;
}

const getWindow = <T extends ServerDataBase = ServerDataBase>
  () => window as unknown as WindowLocal<T>;

export const getServerData = <T extends ServerDataBase = ServerDataBase>(): T => {
  const { serverData = {} as T } = getWindow<T>();

  const USA = serverData.countries?.find((country) => country.alpha2 === 'US') as Country;

  if (serverData.session?.phone && typeof serverData.session.phone === 'string') {
    serverData.session.phone = Number.parseInt(`${serverData.session.phone}`.replace(/\D/g, ''), 10);
  }

  return {
    ...serverData,
    countries: serverData.countries ?? [],
    USA,
  };
};

const data = getServerData();
export const rentersServerData = data as unknown as RentersServerData;
export const formServerData = data as unknown as ServerDataForm;
export const serverData = data;

export const { nonce } = getWindow();
