export enum EnvironmentNames {
  local='local',
  qualityAssurance='quality-assurance',
  userAcceptanceTesting='user-acceptance-testing',
  production='production',
}

export interface ENVValues extends ImportMetaEnv {
  VITE_ENV: EnvironmentNames;
  VITE_API_V2_TOKEN: string;
  VITE_API_GMAPS_KEY: string;
  VITE_ZENDESK: string;
  VITE_BUGSNAG_API_KEY: string;
  VITE_BUGSNAG_NOTIFY_RELEASE_STAGES: string;
}

interface GGDomains {
  gradguard: string;
  enroll: string;
  account: string;
  api: string;
}

const domains: Record<EnvironmentNames, GGDomains> = {
  [EnvironmentNames.local]: {
    gradguard: 'http://gradguard.local',
    enroll: 'http://enroll.gradguard.local',
    account: 'http://account.gradguard.local',
    api: 'http://api.gradguard.local',
  },
  [EnvironmentNames.qualityAssurance]: {
    gradguard: 'https://home.qa.gradguard.com',
    enroll: 'https://enroll.qa.gradguard.com',
    account: 'https://account.qa.gradguard.com',
    api: 'https://api.qa.gradguard.com',
  },
  [EnvironmentNames.userAcceptanceTesting]: {
    gradguard: 'https://home.uat.gradguard.com',
    enroll: 'http://enrolluat.gradguard.com',
    account: 'https://account.uat.gradguard.com',
    api: 'https://api.uat.gradguard.com',
  },
  [EnvironmentNames.production]: {
    gradguard: 'https://gradguard.com',
    enroll: 'http://enroll.gradguard.com',
    account: 'https://account.gradguard.com',
    api: 'https://api.gradguard.com',
  },
};

const envValues = import.meta.env as ENVValues;
const config = {
  env: envValues.VITE_ENV,
  domains: domains[envValues.VITE_ENV],
  tokens: {
    apiV2: envValues.VITE_API_V2_TOKEN,
    gMapsKey: envValues.VITE_API_GMAPS_KEY,
  },
  is: {
    local: envValues.VITE_ENV === EnvironmentNames.local,
    qa: envValues.VITE_ENV === EnvironmentNames.qualityAssurance,
    uat: envValues.VITE_ENV === EnvironmentNames.userAcceptanceTesting,
    prod: envValues.VITE_ENV === EnvironmentNames.production,
  },
};

export default config;
